/**
 * This script dynamically injects another <script> tag into the DOM,
 * which loads the actual Google Tag Manager script and initializes the dataLayer.
 *
 * Reference: https://support.google.com/tagmanager/answer/14847097
 * */
export const GoogleTagManagerScript = ({
  gtmId,
  nonce,
}: {
  gtmId: string
  nonce: string
}) => {
  return (
    <script
      nonce={nonce}
      dangerouslySetInnerHTML={{
        __html: `
              (function (w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
                var f = d.getElementsByTagName(s)[0],
                  j = d.createElement(s),
                  dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
              })(window, document, 'script', 'dataLayer', '${gtmId}');
            `,
      }}
    />
  )
}

/**
 * NoScript Fallback for Google Tag Manager
 *  */
export const GoogleTagManagerNoscript = ({ gtmId }: { gtmId: string }) => {
  return (
    <noscript>
      <iframe
        title='Google Tag Manager'
        src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
        height='0'
        width='0'
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>
  )
}

export const sendGTMEvent = (data: unknown) => {
  // define dataLayer so we can still queue up events before GTM init
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(data)
}
